import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef} from '@angular/material/snack-bar';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';
import {ZenIconsEnum} from "../../shared/_enums/zen-icons.enum";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";

export interface ZenToastDataModel {
    message?: string;
    icon?: ZenIconsEnum;
    popperContent?: NgxPopperjsContentComponent;
}

@Component({
    selector: 'app-zen-toast',
    standalone: true,
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatIconModule
    ],
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

    constructor(
        public snackBarRef: MatSnackBarRef<ToastComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: ZenToastDataModel
    ) {
    }

    ngOnInit(): void {
    }

}
